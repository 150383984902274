
import React from "./Home.css"
import Header from "../../Header/Header"
import Blog from "../blog/Blog"
import Topbar from "../../Topbar/Topbar"

function Home() {

  return (
    <>
      {
        <div className="home">
          <Header />
          <Blog />
        </div>
      }

    </>

  )
}

export default Home