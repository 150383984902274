import React from "./Blog.css"
import MainSidebar from "../../MainSidebar/MainSidebar"
import Records from "../../../records.json"
import { NavLink } from "react-router-dom"
function Blog() {
  return (
    <>
      <div className='Blog'>
        <div className='bodyLeft'>
          <div class="container">
            {Records.map(record => {
              return (
                <div className="card" key={record.id}>
                  <div class="card-header">
                    <NavLink to={`/SinglePost/${record.id}`}>
                      <img src={record.image} alt="" className="img-fluid" />

                    </NavLink>
                  </div>
                  <div class="card-body">
                    <span class="tag tag-teal">{record.category}</span>
                    <h4>
                      {record.shortdes}
                    </h4>
                    <p>
                      {record.shortnote}
                    </p>
                    <div class="user">
                      <img src={record.image2} alt="" />
                      <div class="user-info">
                        <h5>{record.creation}</h5>
                        <small>{record.creation1}</small>
                        {/* <small>{record.id}</small> */}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            }



          </div>
          <div class="pagination-container">
            <ul class="pagination">
              <li>
                <a href="#">Prev</a>
              </li>
              <li>
                <a href="#">1</a>
              </li>
              <li class="active">
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">4</a>
              </li>
              <li>
                <a href="#">5</a>
              </li>
              <li>
                <a href="#">Next</a>
              </li>
            </ul>
          </div>
        </div>
        <MainSidebar />

      </div>
    </>
  )

}

export default Blog