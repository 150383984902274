import React from "./SinglePost.css"
import { useParams } from "react-router-dom"
import Records from "../../../records.json"

function SinglePost() {
    const { postId } = useParams();
    // It will return the id number on which we click.
    console.log(postId);
    // console.log(Records[postId].category);
    // console.log(Records.json);
    const image = "." + Records[postId].image;

    return (
        <div className="main">
            <div className='single'>
                <div className='bodyLeft'>
                    <div className="singlePostWrapper">
                        <div className="imgWrap">
                            <img className="singlePostImage" src={image} alt="Image not found" />
                        </div>
                        <h1 className="singlePostTitle">
                            <p style={{ color: "black" }}>{Records[postId].category}</p>
                            <div className="editContainer">
                                <i className="singlePostIcon fa-regular fa-pen-to-square"></i>
                                <i className="singlePostIcon fa-solid fa-trash"></i>
                            </div>
                        </h1>
                        <h1 className="singlePostTitle">
                            <p>{Records[postId].shortdes}</p>
                        </h1>
                        <div className="singlePostInfo">
                            <span className="singlePostAuthor">Author: <b>Sarfraz</b></span>
                            <span className="singlePostDate">Created On: <b>1h Ago</b></span>
                        </div>
                        <p className="des">{Records[postId].description}</p>
                    </div>
                </div>

                <div className='sidebar'>
                   <div className="sidebarItem">
                        <img className="sidebarImage" src={image} alt="Image not found" />
                        <span className="sidebarTitle">About Us</span>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, doloribus.</p>
                    </div>
                    <div className="sidebarItem">
                        <span className="sidebarTitle">Categories</span>
                        <ul className="sidebarList">
                            <li className="sidebarListItem">Happy</li>
                            <li className="sidebarListItem">Sad</li>
                            <li className="sidebarListItem">Cool</li>
                            <li className="sidebarListItem">Play</li>
                        </ul>
                    </div>
                    <div className="sidebarItem">
                        <span className="sidebarTitle">Follow Us</span>
                        <div className="sidebarSocial">
                            <i className="sidbarIcons fa-brands fa-facebook-square"></i>
                            <i className="sidbarIcons fa-brands fa-square-twitter"></i>
                            <i className="sidbarIcons fa-brands fa-square-whatsapp"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default SinglePost