import React from 'react';
import "./Topbar.css"
import { NavLink } from 'react-router-dom';



function Topbar() {

  const user = false;
  function open() {
    document.querySelector('.menu').classList.toggle('toggleMenu');
    document.querySelector('.spinner-bar.top').classList.toggle('toggleTop');
    document.querySelector('.spinner-bar.middle').classList.toggle('toggleMdl');
    document.querySelector('.spinner-bar.bottom').classList.toggle('toggleBtm');
    document.querySelector('.ax').classList.toggle('rotateX');
  }

  function moveX() {
    document.querySelector('.spinner').classList.toggle('rotateHam')
  }

  function hideMenu() {
    document.querySelector('.menu').classList.toggle('toggleMenu');

  }


  return (

    <React.Fragment>

      <div className='top'>
        <h1>Blogging Website</h1>
      </div>
      <nav className="snavbar">
        <ul>
          {
            user ? (<ul className="logo-icon">
              <li className="snavbar-log1">
                <NavLink to="#" className='Navlink'>
                  < img className='topImage' src="blog.jpeg" alt="logo" /></NavLink>
              </li>
              <li className="snavbar-log2">
                <NavLink to="#" className='Navlink'><input placehoder="search" />
                  <i className="topSearchIcon fa-solid fa-magnifying-glass"></i></NavLink>
              </li>
            </ul>) : (
              <ul className="logo-icon">
                <li className="snavbar-log1">
                  <NavLink to="/login" className='Navlink'>
                    <i className="fa-solid fa-arrow-right-to-bracket"></i> log1.</NavLink>
                </li>
                <li className="snavbar-log2">
                  <NavLink to="/register" className='Navlink'><i className="fa-solid fa-clipboard-list"></i> Reg1.</NavLink>
                </li>
              </ul>
            )
          }
          <ul className="menu">
            <li className="snavbar-links" onClick={hideMenu}><NavLink to="/">Home</NavLink></li>
            <li className="snavbar-links" onClick={hideMenu}><NavLink to="/blog">Blog</NavLink></li>
            <li className="snavbar-links" onClick={hideMenu}><NavLink to= "/SinglePost/">Single Post</NavLink></li>
            {/* <li className="snavbar-links" onClick={hideMenu}><NavLink to="/AddBlog">Add-Blog</NavLink></li> */}
            {/* <!-- we will keep this hidden and show when screen size is small, snavbar-links taken so that we keep it just below the bloglist in small screen and show-later taken to show later and now it is hidden  --> */}
            <li className="snavbar-links show-later">
              <span><i className="topIcon fa-brands fa-facebook-square"></i></span>
              <span><i className="topIcon fa-brands fa-square-twitter"></i></span>
              <span><i className="topIcon fa-brands fa-square-whatsapp"></i></span>
            </li>
          </ul>
          <ul className="sicon">
            <li className="sicon-log1"><i className="topIcon fa-brands fa-facebook-square"></i></li>
            <li className="sicon-log2"><i className="topIcon fa-brands fa-square-twitter"></i></li>
            <li className="sicon-log3"><i className="topIcon fa-brands fa-square-whatsapp"></i></li>
            <li className="spinner" onClick={open}>
              <div className="spinner-bar top"></div>
              <div className="spinner-bar middle"></div>
              <div className="spinner-bar bottom"></div>
              <div className="ax" onClick={moveX}>X</div>
            </li>
          </ul>
        </ul>
      </nav>
    </React.Fragment>

  )


}

export default Topbar